import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {Row, Col, Tabs, Button, Modal, Select, DatePicker, Form, Input, message} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {addEvent, getProjects, getEvents, getTasks, getMembers, deleteEvent, getGoogleAuthLink, syncAllEventsWithGoogle, syncEventWithGoogle} from '../actions/mainActions';
import moment from 'moment';
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/ru';
import { TaskView } from "./Tasks/taskView";
import './calendar.scss';

const {Option} = Select;
const {TextArea} = Input;
const {TabPane} = Tabs;

class CalendarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            RangeTasks: [],
            RangeProjects: [],
            RangeEvents: [],
            visible: false,
            visibleEvent: false,
            event_name: '',
            event_desc: '',
            event_date: '',
            defaultEventValue: moment(),
            members: [],
            membersEvent: [],
            project_name: '',
            projects: [],
            taskId: null,
            taskVisible: false
        };
    }

    componentDidMount() {
        getMembers().then((response) => {
            this.setState({
                members: response.data.data
            })
        })
        getProjects().then((response) => {
            const ProjectsList = !!response.data.data ? Object.values(response.data.data) : [];
            this.setState({projects: response.data.data})
            const arrToSave = []
            ProjectsList.map((item) => {
                arrToSave.push(
                    {
                        title:
                        <Link to={{pathname: `/project/${item.id}`, propsSearch: item.id}}>
                                {item.name}
                            </Link>,
                        id: item.id,
                        key: item.id,
                        start: new Date(item.start),
                        end: new Date(item.end),
                        
                    }
                    );
                    
                    return null
            });
            this.setState({
                events: arrToSave
            })
        })
        getTasks().then((response) => {
            const TasksList = !!response.data.data ? Object.values(response.data.data) : [];
            TasksList.map((item) => {
                this.state.RangeTasks.push(
                    {
                        title: item.name,
                        id: item.id,
                        key: item.id,
                        start: new Date(item.start),
                        end: new Date(item.end),
                    }
                );
                return null
            });
        })
        this.getEvent();
    }

    componentDidUpdate = (prevProps,prevState) =>{
        
        // if(this.state.event_name !== prevState.event_name){
        //     this.setState((s)=> ({
        //         ...s,
        //         event_name: this.state.event_name
        //     }))
        // }
        // if(this.state.RangeEvents!==prevState.RangeEvents){
        //     this.setState((s)=> ({
        //         ...s,
        //         RangeEvents: [...this.state.RangeEvents]
        //     }))
        // }
    }

    handleOk = (e) => {
        const objToSave = e;
        objToSave.name = e.name;
        objToSave.description = e.description;
        objToSave.date = moment(e.date).format("YYYY-MM-DD HH:mm");
        objToSave.members = e.members;
        objToSave.project_id = e.project_id;

        const userInfo = JSON.parse(localStorage.getItem('user'));
        const googleToken = userInfo['google_integration'] ? userInfo['google_integration'] : null;

        if (googleToken) {
            objToSave.google_calendar_sync = 1;
        }

        addEvent(objToSave).then((res) => {
            message.success("Событие создано");
            this.setState({
                visible: false
            });
            this.refs["form"] && this.refs["form"].resetFields();

            this.getEvent()
        });
    }


    getEvent = () => {
        getEvents().then((response) => {
            let eventsList = !!response.data.data ? Object.values(response.data.data) : [];
            eventsList = eventsList.map((item) => {
                // console.log(item)
                return {
                    title: item.name,
                    key: item.id,
                    start: new Date(item.date),
                    end: new Date(item.date),
                    description: item.description,
                    members: item.members,
                    project_id: item.project_id,
                    project_name: item.project_name,
                    event_id: item.id,
                }
            })
            this.setState({
                RangeEvents: eventsList,
                visible: false
            })
        })
    }

    onFinishFailed = () => {
        message.error("Проверьте введенный поля");
    };

    hideModal = () => {
        this.setState({
            visible: false,
            visibleEvent: false
        });
    };
    showModal = e => {
        this.setState({
            visible: true,
            defaultEventValue: moment(e.start),
        });
    };

    handleSelect(ranges, key) {
        //  alert('ZAEBIS')

    }

    RouteProject = (id) => {
        this.props.history.push(`/project/${id}`)
    }

    RouteTask = (id) => {
        this.setState({
            taskVisible: true,
            taskId: Number(id)
        })
        // this.props.history.push(`/board/${Number(id)}`);
    }

    showEvent(event) {
        // console.log(event)
        this.setState({
            visibleEvent: true,
            event_name: event.title,
            event_desc: event.description,
            event_date: event.end,
            membersEvent: event.members,
            project_name: event.project_id,
            event_id: event.event_id,
        })
    }

    deleteEvent(eventId) {
        // console.log(`deleting ${eventId}`);
        deleteEvent(eventId).then((response) => {
            message.success(`Событие успешно удалено`)
            this.getEvent();
            this.hideModal();
        })
    }

    handleSyncAllEvents() {
        message.info(`Посылаю запрос на синхронизацию`);
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const googleToken = userInfo['google_integration'] ? userInfo['google_integration'] : null;

        if (googleToken) {
            syncAllEventsWithGoogle().then((response) => {
                message.success(`События синхронизированы!`)
            })
        } else {
            getGoogleAuthLink().then((response) => {
                window.location.href = response.data.redirect;
            })
        }
    }

    handleSyncEvent(eventId) {
        message.info(`Посылаю запрос на синхронизацию`);
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const googleToken = userInfo['google_integration'] ? userInfo['google_integration'] : null;

        if (googleToken) {
            syncEventWithGoogle(eventId).then((response) => {
                message.success(`Событие синхронизировано!`)
            })
        } else {
            getGoogleAuthLink().then((response) => {
                window.location.href = response.data.redirect;
            })
        }
    }

    render() {
        const localizer = momentLocalizer(moment);
        return (
            <Tabs defaultActiveKey="1"
                //onChange={callback}
                style={{marginBottom: "30px"}}
            >
                <TabPane tab="Календарь проектов" key="1">
                    <Calendar
                        culture='ru'
                        selectable
                        localizer={localizer}
                        events={this.state.events}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectSlot={this.handleSelect}
                        style={{height: "100vh"}}
                        messages={{
                            next: "Следующий",
                            previous: "Предыдущий",
                            today: "Сегодня",
                            month: "Месяц",
                            week: "Неделя",
                            day: "День",
                            noEventsInRange: "Нет событий в диапозоне дат",
                            noData: "Пусто",
                            showMore: function (e) {
                                return '+' + e + ' больше'
                            },
                            time: 'Время',
                            event: 'Событие'
                        }}
                    />
                </TabPane>
                <TabPane tab="Календарь задач" key="2">
                    <Calendar
                        selectable
                        localizer={localizer}
                        events={this.state.RangeTasks}
                        //  defaultView={Views.MONTH}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        onSelectEvent={event => this.RouteTask(event.id)}
                        onSelectSlot={this.handleSelect}
                        style={{height: "100vh"}}
                        messages={{
                            next: "Следующий",
                            previous: "Предыдущий",
                            today: "Сегодня",
                            month: "Месяц",
                            week: "Неделя",
                            day: "День",
                            noEventsInRange: "Нет событий в диапозоне дат",
                            noData: "Пусто",
                            showMore: function (e) {
                                return '+' + e + ' больше'
                            },
                            time: 'Время',
                            event: 'Событие'
                        }}
                    />
                    {
                        this.state.taskVisible &&
                        <TaskView
                            taskId={this.state.taskId}
                            taskVisible={this.state.taskVisible}
                            handleCancel={() => this.setState({ taskId: null , taskVisible: false })}
                        />
                    }
                </TabPane>
                <TabPane tab="Календарь событий" key="3">
                    <Row>
                        <Col span={6}>
                            {/* <Button type="primary" size="large" onClick={this.showModal}>
                    Добавить Событие <PlusCircleOutlined/>
                  </Button> */}
                        </Col>
                        <Col span={24}>
                            <Calendar
                                selectable
                                localizer={localizer}
                                events={this.state.RangeEvents}
                                //  defaultView={Views.MONTH}
                                scrollToTime={new Date(1970, 1, 1, 6)}
                                defaultDate={new Date()}
                                onSelectEvent={event => this.showEvent(event)}
                                onSelectSlot={event => this.showModal(event)}
                                style={{height: "100vh"}}
                                messages={{
                                    next: "Следующий",
                                    previous: "Предыдущий",
                                    today: "Сегодня",
                                    month: "Месяц",
                                    week: "Неделя",
                                    day: "День",
                                    noEventsInRange: "Нет событий в диапозоне дат",
                                    noData: "Пусто",
                                    showMore: function (e) {
                                        return '+' + e + ' больше'
                                    },
                                    time: 'Время',
                                    event: 'Событие'
                                }}
                            />
                        </Col>
                    </Row>
                    <Modal
                        title="Добавить событие"
                        visible={this.state.visible}
                        width={"50%"}
                        footer={null}
                        onOk={this.hideModal}
                        onCancel={this.hideModal}
                    >
                        <Form
                            ref="form"
                            name="basic"
                            initialValues={{
                                date: this.state.defaultEventValue
                            }}
                            onFinish={this.handleOk}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row gutter={12}>
                                <Col span={4}>
                                    <h3>Название</h3>
                                </Col>
                                <Col span={20}>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Введите название задачи"
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={"Введите название события"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={4}>
                                    <h3>Описание</h3>
                                </Col>
                                <Col span={20}>
                                    <Form.Item
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Введите описание задачи"
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            rows={4}
                                            placeholder="Введите описание задачи"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4}>
                                    <h3>
                                        Дата{" "}
                                        <span className={"lableToInputArrow"}>
        </span>
                                    </h3>{" "}
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Выберите крайнюю дату"
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            size="large"
                                            format="DD.MM.YYYY HH:mm"
                                            placeholder="Выберите дату"
                                            onChange={(dateString) =>
                                                this.setState({newCardDate: dateString})
                                            }
                                            showTime
                                        />
                                    </Form.Item>
                                </Col>


                            </Row>
                            <Row>
                                <Col span={4}>
                                    <h3>
                                        Участники{" "}
                                        <span className={"lableToInputArrow"}>
        </span>
                                    </h3>{" "}
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="members"

                                    >
                                        <Select
                                            placeholder='Выберите участников'
                                            mode='multiple'
                                            size='large'
                                        >
                                            {this.state.members.map(item => (
                                                <Option key={item.id} value={item.id}>{item.first_name} {item.last_name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4}>
                                    <h3>
                                        Проект{" "}
                                        <span className={"lableToInputArrow"}>
        </span>
                                    </h3>{" "}
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="project_id"

                                    >
                                        <Select
                                            size='large'
                                            placeholder='Выберите проект'
                                        >
                                            {this.state.projects.map(item => (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>


                            </Row>
                            <Row className={"modalFooter"}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            style={{width: "100%"}}
                                            onClick={
                                                this.getEvent
                                            }
                                        >
                                            Создать событие
                                            <PlusCircleOutlined/>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Modal
                        title="Событие"
                        visible={this.state.visibleEvent}
                        width={"50%"}
                        footer={null}
                        onOk={this.hideModal}
                        onCancel={this.hideModal}
                    >
                        <Row gutter={16} style={{marginBottom: "25px"}}>
                            <Col span={8}>
                                <h3>Название</h3>
                            </Col>
                            <Col span={16}>
                                {this.state.event_name}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom: "25px"}}>
                            <Col span={8}>
                                <h3>Описание</h3>
                            </Col>
                            <Col span={16}>
                                {this.state.event_desc}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom: "25px"}}>
                            <Col span={8}>
                                <h3>Дата</h3>
                            </Col>
                            <Col span={16}>
                                {moment(this.state.event_date).format('DD.MM.YYYY')}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom: "25px"}}>
                            <Col span={8}>
                                <h3>Участники</h3>
                            </Col>
                            <Col span={16}>
                                {this.state.membersEvent && this.state.membersEvent.map((item) => (
                                    <span key={item.id}>{item.first_name} {item.last_name},</span>))}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom: "25px"}}>
                            <Col span={8}>
                                <h3>Проект</h3>
                            </Col>
                            <Col span={16}>
                                {/* {this.state.project_name && this.state.projects.indexOf((item) => item.id === this.state.project_name).name} */}
                                {this.state.RangeEvents.map(item => {
                                    if(item.title === this.state.event_name){
                                        return item.project_name
                                    }
                                    // if(item.project_name === this.state.event_name){
                                    //     return item.project_name
                                    // }
                                    // else{
                                    //     return this.state.event_name
                                    // }
                                     })}
                            </Col>
                        </Row>
                        <Row>
                            <Button className="calendar__sync" onClick={() => {this.handleSyncEvent(this.state.event_id)}}>
                                Синхронизировать с Google
                            </Button>
                            <Button onClick={() => {this.deleteEvent(this.state.event_id)}} style={{marginLeft: 'auto'}}>
                                Удалить
                            </Button>
                        </Row>
                    </Modal>
                </TabPane>
                <Button className="calendar__sync" onClick={() => {this.handleSyncAllEvents()}}>
                    Синхронизировать все события с Google
                </Button>
                {/* { (this.state.events.length === 0 || this.state.RangeTasks.length === 0 || this.state.RangeEvents.length === 0) &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 0, left: 0,
                            width: '100%',
                            background: '#fff',
                            opacity: '.7',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '10'
                        }}
                    >
                        <Spin spinning={true} />
                    </div>
                } */}
            </Tabs>
        )
    }
}

export default CalendarMenu;
