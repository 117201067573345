import React, { Component } from "react";
import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Upload,
  Button,
  Empty,
  message
} from 'antd';

import {ReactComponent as BasketIcon} from "../../../assets/images/icons/basket.svg";
import {ReactComponent as TemperatureIcon} from "../../../assets/images/icons/temperature.svg";
import {ReactComponent as SizeIcon} from "../../../assets/images/icons/building_size.svg";
import {ReactComponent as BedIcon} from "../../../assets/images/icons/bed.svg";
import { DeleteOutlined } from '@ant-design/icons';

import {
  BackendUrl,
  getInvestProjectCategories,
  getInvestProjectTypes,
  getInvestProjectKinds,
  getInvestProjectRequiredBuildings,
  createInvestProject,
  addFile,
  deleteFile
} from "../../../actions/mainActions";

import './createProject.scss'
import { Link } from "react-router-dom";
import Header from "../../../components/uiLanding/layouts/Header/header";
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import ApplicationSubmissionModal from "../../../components/uiLanding/applicationSubmissionModal/applicationSubmissionModal";

class CreateProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 1,
      progress: 1,
      regionsData: [],
      selectedRegionId: 1,
      categoriesData: [],
      selectedCategoryId: null,
      typesData: null,
      selectedTypeId: null,
      selectedTypeName: null,
      kindsData: null,
      requiredBuildingsData: null,
      basket: [],
      uniqueBasket: [],
      filesList: [],
      picturesList: [],
      coords: [],
      status: 0,
      requestLoading: false,
      saveLoading: false,
      showModal: false,
      finish: false
    }
    this.formRef = React.createRef()
  }

  goToLandingPage = () => {
    window.location = window.location.origin
  }

  componentDidMount() {
    getInvestProjectCategories().then(response => {
      this.setState({
        categoriesData: response.data.data
      })
    })
  }

  nextTab = () => {
    let plusSum = this.state.current + 1
    if (this.state.current === 3) plusSum += 1

    this.setState({
      current: this.state.current + 1,
      progress: plusSum
    })
  }

  onCategorySelected = categoryId => {
    getInvestProjectTypes(categoryId).then(response => {
      this.setState({
        typesData: response.data.data,
        selectedCategoryId: categoryId
      })
    })
    this.nextTab()
  }
  onTypeSelected = type => {
    getInvestProjectKinds(type.id).then(response => {
      this.setState({
        kindsData: response.data.data,
        selectedTypeId: type.id,
        selectedTypeName: type.name,
        current: 3,
        progress: 3
      })
    })
  }
  onKindSelected = kind => {
    this.setState({
      basket: [...this.state.basket, kind]
    })
  }

  onGoBasket = () => {
    const uniqueItemsCountArray = {}
    const uniqueItemsAndCount = []
    const finished = []
    this.state.basket.forEach(item => { uniqueItemsCountArray[item.id] = (uniqueItemsCountArray[item.id] || 0) + 1; });
    Array.from(new Set(this.state.basket)).forEach(item => {
      if (!finished.includes(item.id)) {
        uniqueItemsAndCount.push({
          count: uniqueItemsCountArray[item.id],
          data: item
        })
        finished.push(item.id)
      }
    })
    getInvestProjectRequiredBuildings(this.state.selectedTypeId).then(response => {
      this.setState({
        uniqueBasket: []
      })
      this.setState({
        uniqueBasket: uniqueItemsAndCount,
        requiredBuildingsData: response.data.data
      })
    })
    this.nextTab()
  }

  plusKind = (kind, count) => {
    this.onKindSelected(kind)
    const uniqueItemsAndCount = []
    
    Array.from(new Set(this.state.uniqueBasket)).forEach(item => {
      uniqueItemsAndCount.push({
        count: item.data.id === kind.id ? count + 1 : item.count,
        data: item.data
      })
    })
    this.setState({
      uniqueBasket: uniqueItemsAndCount,
    })
  }
  minusKind = (kind, count) => {
    this.onKindSelected(kind)
    const uniqueItemsAndCount = []
    
    Array.from(new Set(this.state.uniqueBasket)).forEach(item => {
      uniqueItemsAndCount.push({
        count: (item.data.id === kind.id && count !== 0) ? count - 1 : item.count,
        data: item.data
      })
    })
    this.setState({
      uniqueBasket: uniqueItemsAndCount,
    })
  }

  deleteItemFromBasket = item => {
    const index = this.state.basket.indexOf(item)
    this.state.basket.splice(index, 1)
    this.setState({
      basket: this.state.basket
    })
  }

  uploadFile = ({ file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    const key = "uploadFile";
    message.loading({content: "Загрузка файла...", key});
    addFile(formData).then((res) => {
      message.success({content: "Файл загружен!", key, duration: 2});
      const filesList = {
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      };
      this.setState({
        filesList: [...this.state.filesList, filesList]
      })
    })
  }
  uploadPicture = ({file}) => {
    const reader = new FileReader();
    
    // console.log(file);

    reader.onloadend = () => {
      // console.log(reader.result);
      file['thumbUrl'] = reader.result
      // console.log(file);

      this.setState({
        picturesList: [...this.state.picturesList, file]
      })

    };
    reader.readAsDataURL(file);
  }

  removeFile = file => {
    const filesList = [...this.state.filesList];
    const index = filesList.findIndex((el) => el.uid === file.uid);
    deleteFile(file.response).then((res) => {
      if (index > -1) {
        filesList.splice(index, 1);
      }
      this.setState({
        filesList: filesList,
      });
    });
  }
  removePicture = file => {
    const picturesList = [...this.state.picturesList];
    const index = picturesList.findIndex((el) => el.uid === file.uid);
    // deleteFile(file.response).then((res) => {
      if (index > -1) {
        picturesList.splice(index, 1);
      }
      this.setState({
        picturesList,
      });
    // });
  }

  finishedRequest = () => {
    this.setState({
      finish: true
    })
  }

  onFinishWithRequest = dataUser => {
    this.setState({
      requestLoading: true
    })
    const data = this.formRef.current.getFieldsValue()
    console.log(data);
    this.formRef.current.validateFields().then(res => {
      const formData = new FormData()
      formData.set('name', data.name)
      // Нужно подготовить бэк для этих данных
      formData.set('address', data.address)
      formData.set('cadastral', data.cadastral)

      formData.set('description', data.description)
      this.state.filesList.length && this.state.filesList.forEach(file => {
        formData.append('files[]', file.response)
      })
      data.images && data.images.fileList.forEach(image => {
        formData.append('images[]', image.originFileObj ?? image)
      })
      this.state.uniqueBasket.forEach((item) => {
        formData.append(`items[${item.data.id}]`, item.count)
      })
      formData.set('region_id', this.state.selectedRegionId)
      formData.set('category_id', this.state.selectedCategoryId)
      // Координаты из карты (карта закомментирована, если аппрувнут убрать этот код)
      // if (this.state.coords.length) {
      //   formData.set('coordinates[0]', this.state.coords[0])
      //   formData.set('coordinates[1]', this.state.coords[1])
      // }
      formData.set('status', 0)
      formData.set('fio', dataUser.fio)
      formData.set('email', dataUser.email)
      formData.set('phone', dataUser.phone)

      console.log(formData);
      
      createInvestProject(formData).then(response => {
        message.success("Проект успешно создан")
        this.finishedRequest()
      })
    }, err => err.errorFields.forEach(error => message.error(error.errors[0]))
    )
  }

  onCancel = () => {
    this.goToLandingPage()
  }

  setNewCenterPoint = coords => {
    this.setState({
      coords
    })
  }

  customRenderPicture = (file) => {
    return (
      <div className="createProjectImageHolder">
        <img src={file.thumbUrl} alt={'thumb'} />
        <svg onClick={() => {this.removePicture(file)}} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.04286 5L10 8.95714V10H8.95714L5 6.04286L1.04286 10H0V8.95714L3.95714 5L0 1.04286V0H1.04286L5 3.95714L8.95714 0H10V1.04286L6.04286 5Z" fill="white"/>
        </svg>
      </div>
    )
  }
  
  render() {
    const { TextArea } = Input
    const changeTab = tabNumber => {

      if (tabNumber === 4 || tabNumber === 5) {
        this.setState({
          current: tabNumber - 1
        })
      } else {
        this.setState({
          current: tabNumber
        })
      }
    }

    const totalSumCount = () => {
      let sum = 0
      this.state.uniqueBasket.forEach(item => {
        sum = sum + item.data.price * item.count
      })
      return formatToCurrnecy(sum)
    }

    const totalSumWithBuild = () => {
      let sum = 0;
      if (this.state.requiredBuildingsData && this.state.requiredBuildingsData.length) {
        this.state.requiredBuildingsData.map((building, key) =>
          sum += building.price
        )
      }

      let sumProject = 0
      this.state.uniqueBasket.forEach(item => {
        sum = sum + item.data.price * item.count
      })

      return formatToCurrnecy(sumProject + sum)
    }

    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number) + ' тг'
    }

    const steps = [1, 2, 3, 4, 5];

    const goShowModal = () => {
      const data = this.formRef.current.getFieldsValue();

      if (data.name && data.address) {
        this.setState({
          showModal: true
        })
      }

      else {
        message.error('Необходимо заполнить поля "Название проекта" и "Адрес объекта"')
        return;
      }
    }

    const goHideModal = () => {
      this.setState({
        showModal: false
      })
    }

    return (
      <div className='create-project'>
        <Header />
        <div className="create-project-inner">
          <div className="create-project-header">
            <div className="container">
            <div className="create-project-titles">
              <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Создание проекта</span></div>
              <h2 className="landing-title">Создать проект</h2>
            </div>
            <div className="steps">
              {
                steps.map((step, key) => (
                  <button
                    className={
                      `step
                      ${
                        (
                          step !== 4 ?
                          this.state.progress >= step
                          : this.state.basket.length > 0
                        )
                        ? 'active' : ''
                      }`
                    }
                    onClick={() => { this.state.progress >= step && changeTab(step) }}
                    key={key}
                  />
                ))
              }
            </div>
            </div>
          </div>

          <section className={`create-project-blocks ${this.state.basket.length && this.state.current !== 3 ? 'with-cart' : ''}`}>
            <div className="container">
            <div className={`states-block ${this.state.basket.length > 0 ? 'active' : ''}`}>
              {
                this.state.current === 1 &&
                <div>
                  <h3 className="step-title">Выберите категорию проекта</h3>
                  <div className="step-cards">
                    {
                      this.state.categoriesData.length > 0 ? 
                      <div className="categories-list">
                        {
                          this.state.categoriesData.map(category => (
                            <button
                              key={category.id}
                              onClick={() => this.onCategorySelected(category.id)}
                              className="image-card"
                              style={{backgroundImage: `url('${BackendUrl}${category.image_path}')`}}
                            >
                              <span>{category.name}</span>
                            </button>
                          ))
                        }
                      </div>
                      :
                      <Spin/>
                    }
                  </div>
                </div>
              }
              {
                (this.state.current === 2) &&
                <div>
                  <h3 className="step-title">Выберите тип</h3>
                  <div className="step-cards">
                    {
                      this.state.typesData ? 
                      <div className="types-list">
                        {
                          this.state.typesData.length > 0 ? this.state.typesData.map(type => (
                            <button key={type.id} onClick={() => this.onTypeSelected(type)} className="image-card">
                              <span>{type.name}</span>
                              <img src={`${BackendUrl}${type.image_path}`} alt={type.name}/>
                            </button>
                          )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 5'}}/>
                        }
                      </div>
                      :
                      <Spin/>
                    }
                  </div>
                </div>
              }
              {
                this.state.current === 3 &&
                <div className="type-progects">
                  <h3 className="step-title">{this.state.selectedTypeName}</h3>
                  <div className="step-cards">
                    {
                      this.state.kindsData ? 
                      <div className="kinds-list">
                        {
                          this.state.kindsData.length > 0 ? this.state.kindsData.map(kind => (
                            <div key={kind.id}  style={{cursor: 'pointer', }} className="kind-card" onClick={() => this.onKindSelected(kind)}>
                              <div className="image-card">
                                <img src={`${BackendUrl}${kind.image_path}`} alt={kind.name}/>
                              </div>
                              <div className="kind-data">
                                <div className='name'>{kind.name}</div>
                                <div className="center">
                                  <div className='beds'><BedIcon/><span>{kind.beds}</span></div>
                                  <div className='temperature'><TemperatureIcon/><span>{kind.temperature}</span></div>
                                  <div className='size'><SizeIcon/><span>{kind.size} м<sup>2</sup></span></div>
                                </div>
                                <div className='price'>
                                  <span>от {formatToCurrnecy(kind.price)}</span>
                                  <button className="busket-icon"><BasketIcon/></button>
                                </div>
                              </div>
                            </div>
                          )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 25'}}/>
                        }
                      </div>
                      :
                      <Spin/>
                    }
                  </div>
                </div>
              }
            </div>
            {
              (this.state.current === 3 || this.state.current === 2 || this.state.current === 1) && this.state.basket.length > 0 &&
              <div className="basket-block">
                <div className="title">
                  <h2 className="step-title" style={{ fontWeight: 600, marginBottom: '50px' }}>Выбранные проекты</h2>
                </div>
                <div className="kinds-list">
                  {
                    this.state.basket.map(kind => 
                      <div className="kind-card" key={kind.id}>
                        <div className="image-card-preview">
                          <img src={`${BackendUrl}${kind.image_path}`} alt={kind.name}/>
                        </div>
                        <div className="kind-data">
                          <div className='name'>{kind.name}</div>
                          <div className="center">
                            <div className='beds'><BedIcon/><span>{kind.beds}</span></div>
                            <div className='temperature'><TemperatureIcon/><span>{kind.temperature}</span></div>
                            <div className='size'><SizeIcon/><span>{kind.size} м<sup>2</sup></span></div>
                          </div>
                          <div className='price'>
                            <span>от {formatToCurrnecy(kind.price)}</span>
                            <button className="delete-cart-item" onClick={() => this.deleteItemFromBasket(kind)}><span>Удалить</span> <DeleteOutlined /></button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                <Button
                  type="primary"
                  size="large"
                  className="go-to-basket"
                  onClick={() => this.onGoBasket()}
                >
                  Оформить заявку
                </Button>
              </div>
            }
            {
              this.state.current === 4 &&
              <div className="sending-application">
                <h3 className="step-title">Ваш выбор</h3>
                <div className="basket">
                  {
                    this.state.uniqueBasket.map(item => 
                      <div className={`basket-item ${item.count === 0 ? 'no-active' : ''}`} key={item.data.id}>
                        <div className="basket-item-top">
                          <div className="image-card">
                            <img src={`${BackendUrl}${item.data.image_path}`} alt={item.data.name}/>
                          </div>
                          <div className="basket-item-top-right">
                            <h4 className="name">{item.data.name}</h4>

                            <div className="center">
                              <div className="basket-item-data">
                                <div className="infos">
                                  <p className="info">Вместимость: <span>{item.data.beds} человек</span></p>
                                  <p className="info">Температура: <span>{item.data.temperature}</span></p>
                                  <p className="info">Площадь пола: <span>{item.data.size} м<sup>2</sup></span></p>
                                </div>
                              </div>
                              <div className="basket-item-count-and-price">
                                <h3 className="price">{formatToCurrnecy(item.data.price * item.count)}</h3>
                                
                                <div className="count-control">
                                  <button onClick={() => this.minusKind(item.data, item.count)}><span>-</span></button>
                                  <div className="count"><span>{item.count}</span></div>
                                  <button onClick={() => this.plusKind(item.data, item.count)}><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="description">{item.data.description}</p>
                      </div>
                    )
                  }
                </div>
                <h3 className="step-title">Обязательные постройки</h3>
                <div className="required-building">
                  {
                    this.state.requiredBuildingsData && this.state.requiredBuildingsData.length ?
                    this.state.requiredBuildingsData.map(building => 
                      <div className='required-building-item' key={building.id}>
                        <div className="image-card">
                          <img src={BackendUrl + building.image_path} alt="" />
                        </div>
                        <div>
                          <h4 className="name">{building.name}</h4>
                          <p className="description">{building.description}</p>
                        </div>
                      </div>
                    )
                    : <h4>Обязательных построек нет</h4>
                  }
                </div>
                <div className="total">
                  <p className="title">Итого:</p>
                  <span>{`${totalSumCount()}`}</span>
                  {
                    this.state.requiredBuildingsData && this.state.requiredBuildingsData.length ?
                    this.state.requiredBuildingsData.map((building, key) =>
                      <span className="building" key={key}>{building.name}({formatToCurrnecy(building.price)})</span>
                    ) : null
                  }
                  <span>=</span>
                  <span>{totalSumWithBuild()}</span>
                </div>
                <Form
                  onFinish={goShowModal}
                  ref={this.formRef}
                  className="regForm"
                >
                  <Row>
                    <Col span={5}>
                      <h4>Название проекта <span style={{color: 'red'}}>*</span></h4>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Введите имя проекта",
                          }
                        ]}
                      >
                        <Input placeholder="Введите название проекта" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <h4>Адрес объекта <span style={{color: 'red'}}>*</span></h4>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Введите адрес объекта",
                          }
                        ]}
                      >
                        <Input placeholder="Введите адрес объекта" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <h4>Кадастровый номер</h4>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="cadastral"
                        rules={[
                          {
                            required: false,
                            message: "Введите кадастровый номер",
                          }
                        ]}
                      >
                        <Input placeholder="Введите кадастровый номер" size="large" className="my-ant-input" style={{width: '100%'}}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <h4>Краткое описание</h4>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: false,
                          }
                        ]}
                      >
                        <TextArea placeholder="Введите текст" className="my-ant-textarea"/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <h4>Документы к проекту</h4>
                    </Col>
                    <Col>
                      <Form.Item  name="files">
                        <Upload
                          onRemove={this.removeFile}
                          listType="text"
                          fileList={this.state.filesList}
                          customRequest={this.uploadFile}
                        >
                          {this.state.filesList.length >= 8 ? null : (
                            <Button className="upload-dock" size="large" type={"link"}>
                              + Загрузить документ
                            </Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Пока закомментил, как аппрувнут удаление карты - удалить код */}
                  {/* <Row style={{zIndex: '0', position: 'relative'}}>
                    <Col span={15}>
                    <CenteringMap
                      setNewCenterPoint={this.setNewCenterPoint}
                      coords={this.state.coords}
                    />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={5}>
                      <h4>Фотографии проекта</h4>
                    </Col>
                    <Col>
                      <Form.Item name="images">
                        <Upload
                          onRemove={this.removePicture}
                          listType="picture"
                          fileList={this.state.picturesList}
                          onPreview={() => {}}
                          customRequest={this.uploadPicture}
                          showUploadList={{showRemoveIcon: true,}}
                          itemRender={(node, file) => (this.customRenderPicture(file))}
                          multiple
                        >
                          {this.state.picturesList.length >= 8 ? null : (
                            <Button className="upload-img" size="large" type={"link"}>
                              + Загрузить фотографию
                            </Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <h3 className="gov-support__header">
                      Меры господдержки
                    </h3>
                  </Row>
                  <Row>
                    <div className="gov-support__container">
                      <div className="gov-support__container__item">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" stroke="#28C397" stroke-width="3"/>
                        </svg>
                        <div className="gov-support__container__item__text">
                          <p>
                            Об утверждении Правил возмещения части затрат субъектов предпринимательства по приобретению оборудования и техники для горнолыжных курортов 
                          </p>
                          <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026382?fbclid=IwAR0iMJhXSEzsoAHPaa77EaqJk9GUbQPDRdy7Gy-6s88hNNV5_xbhDDUnwxo">
                            https://adilet.zan.kz/rus/docs/V2100026382?fbclid=IwAR0iMJhXSEzsoAHPaa77EaqJk9GUbQPDRdy7Gy-6s88hNNV5_xbhDDUnwxo
                          </a>
                        </div>
                      </div>

                      <div className="gov-support__container__item">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" stroke="#28C397" stroke-width="3"/>
                        </svg>
                        <div className="gov-support__container__item__text">
                          <p>
                            Об утверждении Правил возмещения части затрат субъектов предпринимательства по строительству объектов придорожного сервиса 
                          </p>
                          <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026375?fbclid=IwAR2I24Vu_l4t0iiEx3jtkoP_RXAdq8Bcvjq20Ct4as1HCdEF6ycZwIFqX_w">
                            https://adilet.zan.kz/rus/docs/V2100026375?fbclid=IwAR2I24Vu_l4t0iiEx3jtkoP_RXAdq8Bcvjq20Ct4as1HCdEF6ycZwIFqX_w
                          </a>
                        </div>
                      </div>

                      <div className="gov-support__container__item">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" stroke="#28C397" stroke-width="3"/>
                        </svg>
                        <div className="gov-support__container__item__text">
                          <p>
                          Об утверждении Правил возмещения части затрат субъектов предпринимательства при строительстве, реконструкции объектов туристской деятельности
                          </p>
                          <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026403?fbclid=IwAR0UdQiBIh_TMk9c50dM3Sw9AnHsNqyS8fAelQG2b3wFB4018JKLcjaYshU">
                            https://adilet.zan.kz/rus/docs/V2100026403?fbclid=IwAR0UdQiBIh_TMk9c50dM3Sw9AnHsNqyS8fAelQG2b3wFB4018JKLcjaYshU
                          </a>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="form-btns" style={{columnGap: '15px'}}>
                    <Button
                      size="large"
                      disabled={this.state.saveLoading}
                      loading={this.state.requestLoading}
                      className="success-button"
                      onClick={goShowModal}
                    >
                      ПОДАТЬ ЗАЯВКУ
                    </Button>
                    <Link to='/'>
                      <Button
                        size="large"
                        loading={this.state.requestLoading}
                        className="cancel-button"
                      >
                        ОТМЕНА
                      </Button>
                    </Link>
                  </Row>
                </Form>
              </div>
            }
            </div>
          </section>
        </div>

        <Footer />
        <ApplicationSubmissionModal
          visible={this.state.showModal}
          finish={this.state.finish}
          loading={this.state.requestLoading}
          hide={goHideModal}
          finishRequest={this.onFinishWithRequest}
        />
      </div>
    )
  }
}

export default CreateProject