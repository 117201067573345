import React, {Component} from "react";
import {BackendUrl, getInvestProjects} from "../../actions/mainActions";
import {Spin, Row, Col} from 'antd';
import {Link} from "react-router-dom";

import './investment.scss'
import VpPagination from "../AdminPanel/VpPagination";

class InvestmentProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectsData: [],
            changeCountPage: 15,
            total: 0,
            from: 0,
            pageSize: 0,
            to: 0,
            current_page: 1,
            last_page: 0,
            loading: true
        }
    }

    changeCountPage = (pageSize) => {
        this.setState({
            pageSize
        })
    }

    componentDidMount() {
        this.getInvestProjects();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.pageSize !== prevState.pageSize) || (this.state.current_page !== prevState.current_page)) {
            this.getInvestProjects();
        }
    }

    getInvestProjects = () => {
        this.setState({
            loading: true
        })
        getInvestProjects(this.state.current_page, this.state.pageSize).then(res => {
            const {total, from, to, current_page, last_page} = res.data.data;
            console.log(total, from, to, current_page, last_page);
            this.setState({
                projectsData: res.data.data.data,
                from,
                to,
                current_page,
                last_page,
                total,
                loading: false
            })
        })
    }


    changePage = current_page => {
        this.setState({
            current_page
        });
    };

    render() {
        const {total, from, to, current_page, last_page, loading} = this.state;
        const formatToCurrnecy = number => {
            return new Intl.NumberFormat('ru-RU').format(number)
        }

        const totalSumWithBuild = (data) => {
            let sum = 0;
            console.log('data.required_buildings', data.required_buildings);
            console.log('data.items', data.items);

            data.required_buildings.forEach((building, key) => {
                sum += building.price;
            })
            console.log('sum after builds', sum);
            data.items.forEach((item, key) =>
                sum += item.price * item.pivot.amount
            )
        
            return sum
        }

        return (
            <div>
                <h2 className='componentTitle'>
                    Инвестиционные проекты
                </h2>
                <div className={`projects-table list`}>
                    <Row className="projects-header" style={{marginBottom: "25px"}}>
                        <h4>Всего {total} проектов</h4>
                    </Row>

                    <Spin spinning={loading}>
                        <div className="projects-list">
                            {
                                this.state.projectsData.map((project, key) => (
                                    <Row className='projects-row'
                                         style={{justifyContent: 'space-between', marginBottom: "25px"}} key={key}>
                                        <Col span={7}>
                                            <Link className='projects-link'
                                                  to={`/investment/project/${project.id}`}>
                                                <div className='image-card'>
                                                    <img alt='iamge-card'
                                                        src={project.image_path ? `${BackendUrl}/${project.image_path}` : require('../../assets/images/default-project.jpg')}/>
                                                </div>
                                                <h4 className="title project-name">{project.name}</h4>
                                            </Link>
                                        </Col>
                                        <Col span={3}>
                                            <h4 className="title">Стоимость (тг)</h4>
                                            <p>{formatToCurrnecy(totalSumWithBuild(project))}</p>
                                        </Col>
                                        <Col span={3}>
                                            <h4 className="title">Дестинация</h4>
                                            <p>{project.region ? project.region.name : '-'}</p>
                                        </Col>
                                        <Col span={3}>
                                            <h4 className="title">Тип</h4>
                                            <p>{project.category ? project.category.name :  '-'}</p>
                                        </Col>
                                        <Col span={3}>
                                            <h4 className="title">Статус</h4>
                                            <p>
                                                {
                                                    project.status === '0'
                                                        ?
                                                        <span className="status yellow">В процессе</span>
                                                        :
                                                        project.status === '1'
                                                            ?
                                                            <span className="status green">Принято</span>
                                                            :
                                                            project.status === '2'
                                                                ?
                                                                <span className="status red">Отклонено</span>
                                                                :
                                                                <span className="status gray">Черновик</span>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <VpPagination
                                changeCountPage={this.changeCountPage}
                                changePage={this.changePage}
                                total={total}
                                from={from}
                                to={to}
                                current_page={current_page}
                                last_page={last_page}/>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}

export default InvestmentProjects
