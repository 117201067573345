import React, {Component} from "react";
import {
    Col,
    Avatar,
    Input,
    Layout,
    Menu,
    message,
    Modal,
    Popover,
    Row,
    Select,
} from "antd";
import {BackendUrl} from "../../actions/mainActions";
import "../../../node_modules/antd/dist/antd.css";
import GlobalMap from "../ProjectsMap/globalMap";
import {ReactComponent as HomeIcon} from "../../assets/images/icons/home.svg";
import {ReactComponent as Logo} from "../../assets/images/icons/newLogo.svg";
import {ReactComponent as TasksIcon} from "../../assets/images/icons/tasks.svg";
import {ReactComponent as CalendarIcon} from "../../assets/images/icons/calendar2.svg";
import {ReactComponent as MapIcon} from "../../assets/images/icons/map2.svg";
import {ReactComponent as DiagramsIcon} from "../../assets/images/icons/diagrams.svg";
import {ReactComponent as Exit} from "../../assets/images/icons/Vectorexit.svg";
import {ReactComponent as ProjectIcon} from "../../assets/images/icons/project.svg";
import {ReactComponent as AdminIcon} from "../../assets/images/icons/admin2.svg";
import {ReactComponent as Bell} from "../../assets/images/icons/bell-outline.svg";
import {ReactComponent as Vector} from "../../assets/images/icons/Vector.svg";
import {ReactComponent as NotifRed} from "../../assets/images/icons/notifRed.svg";
import {ReactComponent as ProblemsIcon} from "../../assets/images/icons/problems.svg";
import {ReactComponent as LibraryIcon} from "../../assets/images/icons/library.svg";
import {ReactComponent as SupporgSvg} from "../../assets/images/icons/support.svg";
import {ReactComponent as Archive} from "../../assets/images/icons/archive.svg";
import {
    ImportOutlined,
    LockOutlined,
    UserOutlined,
    SafetyCertificateOutlined,
    BookOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import {MyProjectss} from "../Projects/myProjects";
import {Panel} from "../Panel/panel";
import CalendarMenu from "../calendar";
import {Auth} from "../Auth/index";
import {TaskList} from "../Tasks/taskList";
import Profile from "../Profile/profile";
import "./mainPage.scss";
import {
    Link,
    Redirect,
    Route,
    Switch,
    NavLink,
} from "react-router-dom";
import RegisterMain from "../Registration";
import {getNotifs, getProfile} from "../../actions/mainActions";
import {Project} from "../Projects/project";
import AdminPanel from "../AdminPanel/users-panel/adminPanel";
import Diagrams from "../diagrams";
import NotificationsPage from "../notifications";
import moment from "moment";
import Problems from "../problems/problems";
import ChangePassword from "../Auth/changePassword";
import Dictionary from "../AdminPanel/dictionary/dictionarys";
import SearchPage from "../searchPage";
import {withRouter} from "react-router-dom";
import Library from "../library";
import ArchiveList from "../Tasks/ArchiveList";
import InvestmentProjects from "../InvestmentModule/investmentProjects";
import InvestmentProject from "../InvestmentModule/investmentProject";
import investmentLibrary from "../InvestmentModule/investmentLibrary";
import CallBackRequests from "../CallBackRequestsModule/callback";


const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;
const {Search} = Input;
const {Option} = Select;
const path = window.location.pathname;

class MainPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changePasswordVisible: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            visible: true,
            avatar: {},
            activeMenu: path.slice(1),
            userAuth: true,
            globalMapFullWidth: false,
            visibleSupport: false,
            role: "user",
            userRole: "Пользователь",
            notifications: [],
            searchType: "project",
            searchValue: "",
            permissions: [],
        };
    }

    searchRef = React.createRef();

    onSearch = (value) => {
    };
    closeChangePassModal = () => {
        this.setState({
            changePasswordVisible: false,
        });
    };

    hideSupport = () => {
        this.setState({
            visibleSupport: false,
        });
    };
    showSupport = () => {
        this.setState({
            visibleSupport: true,
        });
        message.info("Модуль в разработке");
    };

    setVisible() {
        this.setState({
            visible: true,
        });
    }

    componentDidMount() {
        // const {activeMenu} = this.state;
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null;
        if (!token || token === "undefined") {
            /*   message.error("Вы не авторизованы");*/
            this.setActiveMenu("auth");
            this.setState({
                userAuth: false,
            });
        } else {
            if (window.location.pathname === "/") {
                if (user.role === "investor") window.location.href = "/investment/main";
                else window.location.href = "/panel";
            } else {
                this.setNewUserProfile();
                this.getNewNotif();
                this.getNewNotifWithTimer();
            }
        }
        this.unSetAuthUrl();
    }

    getNewNotifWithTimer = () => {
        setInterval(() => this.getNewNotif(), 30000);
    };

    getNewNotif = () => {
        getNotifs().then((res) => {
            this.setState({
                notifications: res.data.data.data,
            });
        });
    };

    setNewUserProfile = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!!user) {
            this.setState({
                first_name: user.first_name,
                middle_name: user.middle_name,
                avatar: user.avatar,
                last_name: user.last_name,
                role: user.role,
                userRole: this.renderRole(user.role),
            });
        }

        if (user.permissions.length > 0) {
            let statePermissions = [];

            user.permissions.forEach((permission) => {
                if (permission.slug) {
                    statePermissions.push(permission.slug);
                }
            })

            this.setState({
                permissions: statePermissions
            })
        }

    };

    unSetAuthUrl = () => {
        if (path === "/auth" || path === "/registration") {
            this.setActiveMenu("auth");
        }
    };

    getLogin = () => {
        getProfile().then((response) => {
            this.setState({
                first_name: response.data.data.first_name,
                middle_name: response.data.data.middle_name,
                last_name: response.data.data.last_name,
                avatar: response.data.data.avatar,
                role: response.data.data.role,
                userRole: this.renderRole(response.data.data.role),
            });

            if (response.data.data.permissions.length > 0) {
                let statePermissions = [];

                response.data.data.permissions.forEach((permission) => {
                    if (permission.slug) {
                        statePermissions.push(permission.slug);
                    }
                })

                this.setState({
                    permissions: statePermissions
                })
            }

            localStorage.setItem("user", JSON.stringify(response.data.data));
        });
    };

    renderRole = (e) => {
        switch (e) {
            case "user":
                return "Пользователь";
            case "root":
                return "Супер администратор";
            case "admin":
                return "Администратор";
            case "investor":
                return "Инвестор";
            default:
                return "Нет роли";
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {first_name, visibleSupport} = this.state;
        if (prevState.visibleSupport !== visibleSupport) {
            return false;
        } else {
            if (!first_name) {
                const token = localStorage.getItem("token");
                token &&
                getProfile().then((response) => {
                    this.setState({
                        first_name: response.data.data.first_name,
                        middle_name: response.data.data.middle_name,
                        last_name: response.data.data.last_name,
                        avatar: response.data.data.avatar
                    });
                });
            }
        }
    }

    setActiveMenu = (e) => {
        sessionStorage.setItem("activeMenu", e);
        this.setState({
            activeMenu: e,
        });
    };

    setGlobalMapFullWidth = () => {
        const {globalMapFullWidth} = this.state;
        this.setState({
            globalMapFullWidth: !globalMapFullWidth,
        });
    };

    handleTaskMenu = () => {
    };

    render() {
        const selectAfter = (
            <Select
                defaultValue="project"
                className="select-after"
                onChange={(e) => this.setState({searchType: e})}
                showArrow={false}
            >
                <Option value="project">Поиск по проектам</Option>
                <Option value="task">Поиск по задачам</Option>
                <Option value="problem">Поиск по проблемам</Option>
            </Select>
        );
        const {history, location} = this.props;
        const {
            searchType,
            searchValue,
            activeMenu,
            globalMapFullWidth,
            notifications,
            changePasswordVisible,
            userAuth,
            visible,
            role,
            avatar,
            first_name,
            last_name,
            userRole,
            visibleSupport,
        } = this.state;
        const user = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null;
        return (
            <div className="mainLayout">
                <ChangePassword
                    handleCancel={this.closeChangePassModal}
                    changePasswordVisible={changePasswordVisible}
                />
                {!userAuth && <Redirect to={"/auth"}/>}
                {activeMenu !== "auth" && (
                    <Header
                        style={{
                            background: "#fdfdfd",
                            boxShadow: "4px 0px 30px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Row
                            gutter={24}
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: 0,
                            }}
                        >
                            <Col span={12} className={`header-inner ${role}`}>
                                <Link
                                    to="/"
                                    onClick={() => this.setState({activeMenu: ""})}
                                    className={activeMenu === "auth" ? "logo logoAuth" : "logo"}
                                    style={{
                                        display: "flex",
                                        width: 200 - 24,
                                        padding: 0,
                                        alignItems: "center",
                                    }}
                                >
                                    <Logo style={{height: 80}}/>
                                </Link>
                                {role !== "investor" && (
                                    <Col
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingLeft: 37,
                                            width: "100%",
                                            maxWidth: 700,
                                        }}
                                    >
                                        <Search
                                            style={
                                                visible
                                                    ? {
                                                        border: "1px solid #E1E8EF",
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                    }
                                                    : {display: "none"}
                                            }
                                            className="searchField"
                                            enterButton={
                                                <Link
                                                    ref={this.searchRef}
                                                    className="searchGttn"
                                                    to={`/search/${searchType}/${searchValue}`}
                                                >
                                                    <SearchOutlined/>
                                                </Link>
                                            }
                                            addonBefore={selectAfter}
                                            onPressEnter={() => this.searchRef.current.click()}
                                            onChange={(e) => {
                                                this.setState({searchValue: e.target.value});
                                            }}
                                            size="large"
                                        />
                                    </Col>
                                )}
                                {role === "investor" && (
                                    <div className="contactLinks">
                                        <p className="description">Если вы нашли интересный проект для вас, просим
                                            обратиться по номеру или написать на наш электронный адрес</p>
                                        <div className="links">
                                            <a href="tel:+77018884728"> +7(701) 888 47 28</a>
                                            <a href="mailto:y.adilkhanov@gmail.com">y.adilkhanov@gmail.com</a>
                                        </div>
                                    </div>
                                )}
                            </Col>
                            <Col span={12} className={'profileHead'}>
                                {role !== "investor" && (
                                    <div style={{marginRight: "20px"}}
                                    >
                                        <Popover
                                            content={
                                                notifications.length > 0 &&
                                                notifications.map(
                                                    (el, i) =>
                                                        i < 3 && (
                                                            <div key={el.id}>
                                                                <b>
                                                                    {el.name} -{" "}
                                                                    {moment(el.date).format("DD.MM.YYYY")}
                                                                </b>
                                                                <Link to={"/notifications"}>
                                                                    <p>{el.description}</p>
                                                                </Link>
                                                            </div>
                                                        )
                                                )
                                            }
                                            title={<h4>Уведомления</h4>}
                                            trigger="hover"
                                        >
                                            <Link to={"/notifications"}>
                                                <div className={"notificationsHeader"}>
                                                    {notifications.length > 0 && (
                                                        <NotifRed className="notifIcon"/>
                                                    )}
                                                    <Bell/>
                                                </div>
                                            </Link>
                                        </Popover>
                                    </div>
                                )}
                                <div className="accountRow">
                                    <div className="accountInfo">
                                        <div className="userPhoto">
                                            <Avatar
                                                size={40}
                                                icon={<UserOutlined/>}
                                                src={`${BackendUrl}/${avatar}`}
                                            />
                                        </div>
                                        <div>
                                            <b>{first_name} {last_name}</b>
                                            <span>{userRole}</span>
                                        </div>
                                    </div>
                                    <button className="accountButton">
                                        <Vector className="vector"/>
                                        <Menu className="userDropDown">
                                            <Menu.Item
                                                className="profileDropdown"
                                                key="profile"
                                            >
                                                <Link to="/profile">
                                                    <UserOutlined/> <span className="text">Профиль</span>
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item
                                                key="reset-password"
                                                style={{paddingLeft: "14px"}}
                                                onClick={() => this.setState({changePasswordVisible: true})}
                                            >
                                                <LockOutlined/> <span className="text">Сменить пароль</span>
                                            </Menu.Item>
                                            <Menu.Item
                                                className="profileDropdown"
                                                key="exit"
                                            >
                                                <Link to="/auth" onClick={() => this.setActiveMenu("auth")}>
                                                    <ImportOutlined className="exit"/> <span
                                                    className="text">Выход</span>
                                                </Link>
                                            </Menu.Item>
                                        </Menu>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Header>
                )}
                <Layout>
                    <Sider
                        className={
                            activeMenu === "auth" ? "leftSidebar authSidebar" : "leftSidebar"
                        }
                    >
                        {activeMenu !== "auth" && (
                            <Menu
                                theme="white"
                                mode="inline"
                                onClick={(e) => this.setActiveMenu(e.key)}
                                defaultSelectedKeys={activeMenu}
                                style={visible ? {} : {display: "none"}}
                                selectedKeys={activeMenu}
                            >
                                <>
                                    <Menu.Item
                                        key="panel"
                                        icon={<HomeIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/panel">
                                            <span className="navItemSpan"> Главная панель</span>
                                        </NavLink>
                                    </Menu.Item>
                                    {this.state.permissions.includes('access-projects') && (<Menu.Item
                                        key="projects"
                                        icon={<ProjectIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/projects">
                                            <span className="navItemSpan">Проекты</span>
                                        </NavLink>
                                    </Menu.Item>)}

                                    {this.state.permissions.includes('access-tasks') && (<SubMenu
                                        key="sub3"
                                        title="Задачи"
                                        icon={
                                            <TasksIcon
                                                style={{
                                                    width: 30,
                                                    height: 18,
                                                    paddingRight: 10,
                                                }}
                                            />
                                        }
                                        className={location.pathname === "/board" ? 'activeMenu' : ''}
                                        onTitleClick={() => {
                                            this.setActiveMenu("")
                                            history.push("/board")
                                        }}
                                    >
                                        <Menu.Item
                                            key="archive"
                                            icon={<Archive className="ant-menu-item-icon"/>}
                                        >
                                            <NavLink to="/task/archive">
                                                <span сlassName="navItemSpan">Архив</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>)}

                                    {this.state.permissions.includes('access-diagrams') && (<Menu.Item
                                        key="diagrams"
                                        icon={<DiagramsIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/diagrams">
                                            <span className="navItemSpan">Диаграмма Ганта</span>
                                        </NavLink>
                                    </Menu.Item>)}

                                    {this.state.permissions.includes('access-calendar') && (<Menu.Item
                                        key="calendar"
                                        icon={<CalendarIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/calendar">
                                            <span className="navItemSpan">Календарь</span>
                                        </NavLink>
                                    </Menu.Item>)}

                                    {this.state.permissions.includes('access-project-map') && (<Menu.Item
                                        key="projects-map"
                                        icon={<MapIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/projects-map">
                                            <span className="navItemSpan">Карта проектов</span>
                                        </NavLink>
                                    </Menu.Item>)}

                                    {this.state.permissions.includes('access-problems') && (<Menu.Item
                                        key="problems"
                                        icon={<ProblemsIcon className="ant-menu-item-icon"/>}
                                    >
                                        <NavLink to="/problems">
                                            <span className="navItemSpan">Риски</span>
                                        </NavLink>
                                    </Menu.Item>)}
                                    {this.state.permissions.includes('access-library') && (<Menu.Item
                                        key="library"
                                        icon={
                                            <LibraryIcon className="ant-menu-item-icon library-icon"/>
                                        }
                                    >
                                        <NavLink to="/library">
                                            <span className="navItemSpan">Библиотека</span>
                                        </NavLink>
                                    </Menu.Item>)}
                                </>

                                {this.state.permissions.includes('access-admin-panel') && (
                                    <SubMenu
                                        key="sub2"
                                        title="Панель админа"
                                        icon={<SafetyCertificateOutlined style={{width: 22}}/>}
                                    >
                                        {this.state.permissions.includes('access-admin-panel-users') ? (<Menu.Item
                                            key="users"
                                            icon={<AdminIcon className="ant-menu-item-icon"/>}
                                        >
                                            <NavLink to="/admin-panel-users">
                                                <span className="navItemSpan">Пользователи</span>
                                            </NavLink>
                                        </Menu.Item>) : null}

                                        {this.state.permissions.includes('access-admin-panel-dictionary') ? (<Menu.Item
                                            key="dictionary"
                                            icon={<BookOutlined className="ant-menu-item-icon"/>}
                                        >
                                            <NavLink to="/dictionary">
                                                <span className="navItemSpan">Справочники</span>
                                            </NavLink>
                                        </Menu.Item>) : null}
                                    </SubMenu>
                                )}

                                {this.state.permissions.includes('access-coordinator-panel') && (
                                    <SubMenu
                                        key="sub3"
                                        title="Панель координатора"
                                        icon={<SafetyCertificateOutlined style={{width: 22}}/>}
                                    >
                                        {this.state.permissions.includes('access-coordinator-panel-invest-projects') ? (<Menu.Item
                                            key="investment-projects"
                                            icon={<ProjectIcon className="ant-menu-item-icon"/>}
                                        >
                                            <NavLink to="/investment/projects">
                                                <span className="navItemSpan">Заявки</span>
                                            </NavLink>
                                        </Menu.Item>) : null}

                                        {this.state.permissions.includes('access-coordinator-panel-contact-orders') ? (<Menu.Item
                                            key="callback"
                                            icon={<ProjectIcon className="ant-menu-item-icon"/>}
                                        >
                                            <NavLink to="/investment/callback">
                                                <span className="navItemSpan">Обратная связь</span>
                                            </NavLink>
                                        </Menu.Item>) : null}
                                    </SubMenu>
                                )}

                                <Menu.Item
                                    key="auth"
                                    icon={<Exit/>}
                                    style={{display: "none"}}
                                >
                                    <NavLink to="/auth">
                                        <span className="navItemSpan">Выход</span>
                                    </NavLink>
                                </Menu.Item>
                            </Menu>
                        )}
                        {
                            window.location.pathname === "/auth" && <Link to='/' className="auth-logo-to-landing">
                                <Logo/>
                            </Link>
                        }
                        <div
                            className={
                                activeMenu === "auth" ? "support supportAuth" : "support"
                            }
                            onClick={() => {
                                this.showSupport();
                            }}
                        >
                            <SupporgSvg/>
                        </div>
                    </Sider>
                    <Layout
                        className={
                            activeMenu === "projects-map" && globalMapFullWidth
                                ? "site-layout fullWidthMap"
                                : "site-layout"
                        }
                        style={{
                            marginLeft: "200px",
                            overflow: "hidden",
                            background:
                                activeMenu === "projects-map"
                                    ? "#FCFDFF"
                                    : "background: rgb(252 253 255)",
                            minHeight: "calc(100vh - 81px)",
                        }}
                    >
                        <Content
                            style={{
                                margin: "0 16px",
                                overflowX: "hidden",
                                background: "#FCFDFF",
                            }}
                        >
                            <div
                                style={{
                                    padding: "24px ",
                                    textAlign: "center",
                                    position: "relative",
                                    minHeight: "calc(100vh - 95px)",
                                }}
                            >
                                <Switch/>
                                <Route path="/panel" component={Panel}/>
                                <Route
                                    path="/profile"
                                    render={(props) => (
                                        <Profile
                                            parentProps={props}
                                            setNewUserProfile={this.setNewUserProfile}
                                        />
                                    )}
                                />

                                <Route
                                    path="/projects"
                                    render={(props) => <MyProjectss parentProps={props}/>}
                                />

                                <Route
                                    path="/auth"
                                    render={(props) => (
                                        <Auth
                                            parentProps={props}
                                            setActiveMenu={this.setActiveMenu}
                                            getLogin={this.getLogin}
                                        />
                                    )}
                                />
                                <Route path="/board/:id?" component={TaskList}/>
                                <Route path="/task/archive" component={ArchiveList}/>
                                <Route
                                    path="/list/:status/:projectId/:userId/:taskId"
                                    component={TaskList}
                                />
                                <Route
                                    path="/registration"
                                    render={(props) => <RegisterMain parentProps={props}/>}
                                />
                                {/* <Route path='/project/:id' component={(...props)=><Project {...props} id={3} />} /> */}

                                <Route
                                    path="/project/:id"
                                    render={(props) => <Project parentProps={props}/>}
                                />

                                <Route path="/calendar" component={CalendarMenu}/>
                                <Route
                                    path="/projects-map"
                                    render={(props) => (
                                        <GlobalMap
                                            parentProps={props}
                                            globalMapFullWidth={globalMapFullWidth}
                                            setActiveMenu={this.setActiveMenu}
                                            setGlobalMapFullWidth={this.setGlobalMapFullWidth}
                                        />
                                    )}
                                />
                                <Route
                                    path="/admin-panel-users"
                                    render={(props) => (
                                        <AdminPanel
                                            parentProps={props}
                                            setActiveMenu={this.setActiveMenu}
                                            role={role}
                                            getLogin={this.getLogin}
                                        />
                                    )}
                                />
                                <Route
                                    path="/dictionary"
                                    render={(props) => (
                                        <Dictionary
                                            parentProps={props}
                                            setActiveMenu={this.setActiveMenu}
                                            role={role}
                                            getLogin={this.getLogin}
                                        />
                                    )}
                                />
                                <Route path="/diagrams" component={Diagrams}/>
                                <Route
                                    path="/notifications"
                                    render={(props) => (
                                        <NotificationsPage
                                            getNewNotif={this.getNewNotif}
                                            notifications={notifications}
                                            parentProps={props}
                                        />
                                    )}
                                />
                                <Route
                                    path="/investment/library"
                                    component={investmentLibrary}
                                />
                                <Route
                                    path="/investment/projects"
                                    component={InvestmentProjects}
                                />
                                <Route
                                    path="/investment/project/:project_id"
                                    render={(props) => <InvestmentProject parentProps={props}/>}
                                />
                                <Route
                                    path="/investment/callback"
                                    component={CallBackRequests}
                                />

                                {/*                <Route path='/problems' render={(props) => (<Problems parentProps={props}/>)}/>*/}
                                <Route
                                    path="/problems/:id?"
                                    render={(props) => <Problems parentProps={props}/>}
                                />
                                <Route path="/search/:type/:text" component={SearchPage}/>
                                <Route path="/library" component={Library}/>
                                {/* INVESTMEN MODULE ROUTES */}
                                <Switch/>
                            </div>
                        </Content>
                        {/*            <Footer style={{textAlign: "right"}}></Footer>*/}
                    </Layout>

                    <Modal
                        title="Техническая поддержка"
                        visible={visibleSupport}
                        onOk={this.hideSupport}
                        onCancel={this.hideSupport}
                        footer={null}
                    >
                        <h3>
                            Email: <a href="mailto:ergoja2211@gmail.com">info@codetau.com</a>
                        </h3>
                        <h3>
                            Контактный номер:{" "}
                            <ButtonMailto mailto="tel:+77002879999" label="+77002879999"/>
                        </h3>
                    </Modal>
                </Layout>
            </div>
        );
    }
}

const ButtonMailto = ({mailto, label}) => {
    return (
        <Link
            to="#"
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default withRouter(MainPanel);
