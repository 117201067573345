import React, { Component } from "react";

// Icons - Images
import { ReactComponent as LogoTourism } from "../../../../assets/images/icons/kazakh-tourism-logo.svg";
import { ReactComponent as LogoKazakhstanTravel } from "../../../../assets/images/icons/kazakhstan-travel-logo.svg";
import { ReactComponent as IconArrowWithCircle } from "../../../../assets/images/icons/arrow-with-circle.svg";

// CSS Styles
import './header.scss'

class HeaderTop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      linksCategory: 'business'
    }
  }

  
  render() {
    const setShowMenu = () => {
      document.body.classList.remove("no-scroll");
      if (!this.state.showMenu) document.body.classList.add("no-scroll");
      this.setState({
        showMenu: !this.state.showMenu
      })
    }

    const setLinksCategory = (type) => {
      this.setState({
        linksCategory: type
      })
    }

    return (
      <div className="header-landing-top">
        <div className="container">
          <a target="_blank" rel="noopener noreferrer" href="https://qaztourism.kz/">
            <LogoTourism />
          </a>
          <button className={`nav-toggle ${this.state.showMenu ? 'active' : ''}`} onClick={setShowMenu}>
            <span className="nav-toggle-item">Menu</span>
          </button>
        </div>


        <nav className={`main-header-nav ${this.state.showMenu ? 'active' : ''}`}>
          <div className="main-header-nav-left">
            <div className="main-header-nav-left-btns">
              <button
                className={`main-header-nav-btn ${this.state.linksCategory === 'business' ? 'active' : ''}`}
                style={{animationDuration: '1.2s'}}
                onClick={() => setLinksCategory('business')}
              >
                Для бизнеса
              </button>
              <button
                className={`main-header-nav-btn ${this.state.linksCategory === 'tourist' ? 'active' : ''}`}
                style={{animationDuration: '1.4s'}}
                onClick={() => setLinksCategory('tourist')}
              >
                Для туриста
              </button>
            </div>

            <a className="logo" target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel">
              <LogoKazakhstanTravel />
            </a>
          </div>
          <div className="main-header-nav-right">
            {
              (this.state.linksCategory === 'business' && this.state.showMenu) && (
                <div className="main-header-nav-links">
                  <a target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel/marketplace" className="main-header-nav-link" style={{animationDuration: '1.1s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Разместите свой бизнес на Национальном туристском портале</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Коммерческая платформа»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.tourismonline.kz/" className="main-header-nav-link" style={{animationDuration: '1.2s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Туристическая экосистема</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Туризм онлайн»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://guides.kazakhstan.travel/ru" className="main-header-nav-link" style={{animationDuration: '1.3s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Стать гидом или экскурсоводом</div>
                      <IconArrowWithCircle />
                    </div>
                  <div className="main-header-nav-link-subtitle">Модуль «Гиды»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://tourstat.kz/" className="main-header-nav-link" style={{animationDuration: '1.4s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">посмотреть статистику в сфере туризма</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Tourstat»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://invest.kazakhstan.travel/" className="main-header-nav-link" style={{animationDuration: '1.5s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Стать инвестором туристских проектов</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Инвестиции»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://eqonaq.kz" className="main-header-nav-link" style={{animationDuration: '1.6s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Отправить уведомление о прибытии иностранного гостя</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Информационная система «eQonaq»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://subsidy.kazakhstan.travel/ru" className="main-header-nav-link" style={{animationDuration: '1.7s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Подать заявку на субсидирование</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Субсидирование» </div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel/mice" className="main-header-nav-link" style={{animationDuration: '1.8s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Стать участником mice мероприятий </div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «MICE»</div>
                  </a>
                </div>
              )
            }
            {
              (this.state.linksCategory === 'tourist' && this.state.showMenu) && (
                <div className="main-header-nav-links">
                  <a target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel/" className="main-header-nav-link" style={{animationDuration: '1.1s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">О Казахстане</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Национальный туристский портал</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel/marketplace" className="main-header-nav-link" style={{animationDuration: '1.2s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Забронируйте лучшие экскурсии, отели, автомобили, кемпинги</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Коммерческая платформа»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://guides.kazakhstan.travel/ru" className="main-header-nav-link" style={{animationDuration: '1.3s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Выбрать гида или экскурсовода</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Гиды»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://altynorda.kazakhstan.travel/" className="main-header-nav-link" style={{animationDuration: '1.4s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">750-летие Золотой ордЫ  </div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Золотая орда»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://kazakhstan.travel/photobank" className="main-header-nav-link" style={{animationDuration: '1.5s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">пейзажи казахстана в лучшем качестве  </div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Модуль «Photobank»</div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://eqonaq.kz" className="main-header-nav-link" style={{animationDuration: '1.6s'}}>
                    <div className="main-header-nav-link-content">
                      <div className="main-header-nav-link-title">Отправить уведомление о прибытии иностранного гостя</div>
                      <IconArrowWithCircle />
                    </div>
                    <div className="main-header-nav-link-subtitle">Информационная система «eQonaq»</div>
                  </a>
                </div>
              )
            }
          </div>
        </nav>
      </div>
    );
  }
}

export default HeaderTop;
