import React from 'react';
import {Switch, Route} from 'react-router-dom';
import MainPanel from '../pages/mainPage/mainPage';
import HomePanel from '../pages/uiLanding/Home/home';
import {Auth} from '../pages/Auth/index';
import LandingProjects from '../pages/uiLanding/Projects/projects';
import CreateProject from '../pages/uiLanding/CreateProject/createProject';
import LandingProjectDetail from '../pages/uiLanding/ProjectDetail/projectDetail';
import LandingFinishedProject from '../pages/uiLanding/FinishedProject/finishedProject';
import stateSupportMeasures from '../pages/uiLanding/StateSupportMeasures/stateSupportMeasures';
import AboutSystem from '../pages/uiLanding/System/System';

export const useRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <HomePanel path='/' exact/>
      </Route>
      
      <Route path="/create-projects" component={CreateProject}/>
      <Route path="/landing-projects" component={LandingProjects}/>
      <Route path="/state-support-measures" component={stateSupportMeasures}/>
      <Route path="/landing-project/:project_id" component={LandingProjectDetail}/>
      <Route path="/landing-finished-project/:project_id" component={LandingFinishedProject}/>
      <Route path="/about-system" component={AboutSystem}/>
      <Route>
        <MainPanel path='/main' exact/>
      </Route>
      <Route>
        <Auth path='/auth' component={Auth} exact/>
      </Route>
    </Switch>
  )
}
