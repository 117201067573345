import { Modal } from "antd";
import React, { Component } from "react";
import { Form, Input, Button } from "antd";


// Icons and Images
import { ReactComponent as IconCheck} from '../../../assets/images/icons/check-green-with-circle.svg'

// Styles
import './applicationSubmissionModal.scss'

class ApplicationSubmissionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectsData: [],
      total: [],
      loading: false,
      finish: false
    }
  }

  goToLandingPage = () => {
    window.location = window.location.origin
  }

  render() {
    return (
      <Modal
        className="applicationSubmissionModal"
        title={null}
        centered
        footer={null}
        width={"80%"}
        visible={this.props.visible}
        onOk={() => {}}
        onCancel={this.props.hide}
        maskStyle={{
          background: 'rgba(0, 43, 111, 0.8)',
        }}
        afterClose={() => {
          if (this.props.finish) {
            this.goToLandingPage();
          } else {
            return;
          }
        }}
      >
        {
          this.props.finish ? (
            <>
              <IconCheck className="check" />
              <h2 className="modal-title">Заявка Успешно Подана!</h2>
            </>
          ) : (
            <>
              <h2 className="modal-title">ЗАПОЛНИТЕ ДАННЫЕ</h2>

              <Form
                className="applicationSubmissionModal-regForm"
                name="basic"
                onFinish={this.props.finishRequest}
              >
                <div>
                  <h4>ФИО</h4>
                  <Form.Item
                    name="fio"
                    rules={[
                      {
                        required: true,
                        message: "Введите ФИО"
                      }
                    ]}
                  >
                    <Input placeholder='Введите' size='large'/>
                  </Form.Item>

                  <h4>Email</h4>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Введите email"
                      }
                    ]}
                  >
                    <Input placeholder='Введите' size='large'/>
                  </Form.Item>
                  <h4>Номер телефона</h4>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Введите номер телефона"
                      }
                    ]}
                  >
                    <Input placeholder='Введите номер телефона' size='large'/>
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size='large'
                    loading={this.props.loading}
                    style={{ width: "250px", float: "left" }}
                  >
                    ОТПРАВИТЬ ЗАПРОС
                  </Button>
                </Form.Item>
              </Form>
            </>
          )
        }
      </Modal>
    )
  }
}

export default ApplicationSubmissionModal;
